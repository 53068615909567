<template>
  <Slant :class="$style.mainContainer" :color="$style.colorSlant">
    <div :class="$style.mainContainerInner">
      <div :class="$style.textContainer">
        <TransitionOnScroll :translateY="30" @intersecting="isIntersecting = $event">
          <BeforeHeading>{{ $t('beforeTitle') }}</BeforeHeading>
        </TransitionOnScroll>
        <TransitionOnScroll :translateY="50" :isIntersecting="isIntersecting">
          <Heading :tag="HEADINGS.h2">{{ $t('title') }}</Heading>
        </TransitionOnScroll>
        <TransitionOnScroll :class="$style.text" :translateY="80" :isIntersecting="isIntersecting">
          <LineBreakResolver :text="$t('text')" />
        </TransitionOnScroll>
      </div>
      <TransitionOnScroll
        :class="$style.iconContainer"
        :scale="0.95"
        :isIntersecting="isMqLarge ? isIntersecting : undefined"
      >
        <div :class="$style.iconContainerInner">
          <IconTrees1 :class="$style.iconTrees1" />
          <IconHappyPeople1 :class="$style.iconPeople1" />
          <IconHappyPeople2 :class="$style.iconPeople2" />
          <IconTrees2 :class="$style.iconTrees2" />
        </div>
      </TransitionOnScroll>
    </div>
  </Slant>
</template>

<script>
import { mapGetters } from 'vuex'
import Slant from '@/components/Slant/Slant.vue'
import BeforeHeading from '@/components/Heading/BeforeHeading.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import IconTrees1 from '@/components/_icons/IconTrees1.svg'
import IconHappyPeople1 from '@/components/_icons/IconHappyPeople1.vue' // fix svgo hiding a leg
import IconHappyPeople2 from '@/components/_icons/IconHappyPeople2.svg'
import IconTrees2 from '@/components/_icons/IconTrees2.svg'
import LineBreakResolver from '@/components/LineBreakResolver.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'

export default {
  name: 'HomeGreenService',
  components: {
    Slant,
    BeforeHeading,
    Heading,
    IconTrees1,
    IconHappyPeople1,
    IconHappyPeople2,
    IconTrees2,
    LineBreakResolver,
    TransitionOnScroll,
  },

  data() {
    return {
      HEADINGS,
      isIntersecting: false,
    }
  },

  computed: {
    ...mapGetters({
      isMqLarge: 'viewport/isMqLarge',
    }),
  },
}
</script>

<style lang="scss" module>
@import './home-green-service-settings';

:export {
  colorSlant: $color-brand-sand-bg-light;
}

.mainContainer {
  overflow: hidden;
  background-color: $color-brand-green-bg;
  padding-top: calc(150vw * #{$slant-ratio});
  padding-bottom: calc(300vw * #{$slant-ratio});

  @include min-screen($mq-small) {
    padding-top: calc(120vw * #{$slant-ratio});
    padding-bottom: calc(270vw * #{$slant-ratio});
  }

  @include min-screen($mq-medium) {
    padding-top: calc(90vw * #{$slant-ratio});
    padding-bottom: calc(230vw * #{$slant-ratio});
  }

  @include min-screen($mq-large) {
    padding-top: calc(1vw * #{$slant-ratio});
    padding-bottom: calc(156vw * #{$slant-ratio});
  }
}

.mainContainerInner {
  position: relative;
  width: 100%;
}

.textContainer {
  padding: 0 40px;
  max-width: 450px;
  box-sizing: content-box;

  @include min-screen($mq-small) {
    padding: 0 60px;
  }

  @include min-screen($mq-large) {
    max-width: 320px;
  }

  @include text-container-padding-left-larger;

  @include min-screen($mq-larger) {
    max-width: 350px;
  }
}

.text {
  color: $color-brand-navy;
}

.iconContainer {
  position: relative;
  margin: 8vw auto 0;
  width: 80vw;
  max-width: 660px;
  height: 180px;

  @include min-screen(between($mq-tiny, $mq-small)) {
    margin: 40px auto 0;
    width: 90vw;
  }

  @include min-screen($mq-small) {
    width: 100vw;
  }

  @include min-screen($mq-large) {
    position: absolute;
    top: 50%;
    right: calc(40vw - 380px);
    margin: 0;
    width: calc(105vw - (#{$text-container-padding-left-calc}) - 300px);
  }

  @include min-screen($mq-larger) {
    right: 0;
    width: calc(105vw - (#{$text-container-padding-left-calc}) - 350px);
  }

  @include min-screen($mq-full) {
    max-width: 1150px;
  }
}

.iconContainerInner {
  display: flex;
  width: 100%;
  height: 100%;

  @include min-screen(between($mq-tiny, $mq-small)) {
    width: 100vw;
    min-width: 520px;
  }

  @include min-screen($mq-medium) {
    min-width: 720px;
  }

  @include max-screen($mq-large) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include min-screen($mq-large) {
    width: auto;
    min-width: 0;
    height: auto;
    transform: translate(8.5vw, calc(-55vw * #{$slant-ratio}));
  }

  @include min-screen($mq-full) {
    transform: translate(8.5vw, calc(-40vw * #{$slant-ratio}));
  }
}

.iconTrees1,
.iconPeople1,
.iconPeople2,
.iconTrees2 {
  flex: 0.8;
  height: 100%;
  transform: translateY(25px);
}

.iconTrees1 {
  margin-right: 24px;
  height: 120%;
  transform: none;

  @include min-screen(between($mq-tiny, $mq-small)) {
    margin-right: calc(24vw - 68px);
  }

  @include min-screen($mq-small) {
    margin-right: 40px;
  }

  @include min-screen($mq-large) {
    transform: translateY(-10px);
  }

  @include min-screen($mq-full) {
    margin-right: 50px;
  }
}

.iconPeople1 {
  @include min-screen(between($mq-tiny, $mq-small)) {
    margin-right: calc(24vw - 68px);
  }

  @include min-screen($mq-small) {
    margin-right: 40px;
  }

  @include min-screen($mq-medium) {
    margin-right: 10px;
  }

  @include min-screen($mq-large) {
    margin-right: 40px;
  }

  @include min-screen($mq-full) {
    display: block;
    margin-right: 10px;
  }
}

.iconPeople2 {
  display: none;
  flex: 1;
  margin-right: 30px;

  @include min-screen($mq-medium) {
    display: block;
  }

  @include min-screen($mq-large) {
    display: none;
  }

  @include min-screen($mq-full) {
    display: block;
  }
}

.iconTrees2 {
  display: none;

  @include min-screen(between($mq-tiny, $mq-small)) {
    display: block;
  }
}
</style>

<i18n src="@/locales/home-green-service.json" />
