<template>
  <div v-show="isVisible" :class="$style.container">
    <HomeHero id="home" ref="hero" />
    <HomeSteps id="steps" :class="$style.anchor" />
    <HomeGreenService />
    <HomeChecklist />
    <HomePricing id="prices" :class="$style.anchor" />
    <HomeTestimonials id="testimonials" :class="$style.anchor" />
    <HomeFaqTeaser />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onImagesLoaded } from '@/helpers/images'
import HomeHero from '@/components/Home/HomeHero/HomeHero.vue'
import HomeSteps from '@/components/Home/HomeSteps/HomeSteps.vue'
import HomeGreenService from '@/components/Home/HomeGreenService/HomeGreenService.vue'
import HomeChecklist from '@/components/Home/HomeChecklist/HomeChecklist.vue'
import HomePricing from '@/components/Home/HomePricing/HomePricing.vue'
import HomeTestimonials from '@/components/Home/HomeTestimonials/HomeTestimonials.vue'
import HomeFaqTeaser from '@/components/Home/HomeFaqTeaser/HomeFaqTeaser.vue'

export default {
  name: 'Home',
  components: {
    HomeHero,
    HomeSteps,
    HomeGreenService,
    HomeChecklist,
    HomePricing,
    HomeTestimonials,
    HomeFaqTeaser,
  },

  computed: {
    ...mapState('page', ['isVisible']),
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions({
      showPage: 'page/show',
    }),

    async init() {
      await onImagesLoaded(this.$refs.hero.$el, { background: true })
      await this.$nextTick()
      this.showPage()
    },
  },
}
</script>

<style lang="scss" module>
$anchor-offset: $site-header-height + 20px;
$anchor-offset-small: $site-header-height-small + 20px;

.container {
  background-color: $color-brand-sand-bg;
  width: 100%;
  transition: opacity 0.3s;
}

.anchor {
  &:before {
    content: '';
    display: block;
    margin-top: -$anchor-offset-small;
    height: $anchor-offset-small;
    visibility: hidden;
    pointer-events: none;

    @include min-screen($mq-large) {
      margin-top: -$anchor-offset;
      height: $anchor-offset;
    }
  }
}
</style>
